import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Backdrop, CircularProgress } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useContext, useEffect, useRef, useState } from "react";
import {
  NavLink,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { UserContext } from "../../App";
import Bag from "../../assets/Bag.svg";
import DashboardImg from "../../assets/Category.svg";
import DashNotActive from "../../assets/Dash-not-active.svg";
import InventoryImg from "../../assets/Folder.svg";
import Graph from "../../assets/Graph.svg";
import Logout from "../../assets/Logout.svg";
import Setting from "../../assets/Setting.svg";
import User from "../../assets/User.svg";
import Banners from "./Banners/Banners";
import Content from "./Content/Content";
import DashboardContext from "./Context/DashboardContext";
import CustomerOrder from "./Customers/CustomerOrder";
import Customers from "./Customers/Customers";
import Dashboard from "./Dashboard/Dashboard";
import Inventory from "./Inventory/Inventory";
import NewProduct from "./Inventory/NewProduct";
import ViewProduct from "./Inventory/ViewProduct";
import Orders from "./Orders/Orders";
import CategoryList from "./Settings/Layout/CategoryList";
import PinCodeList from "./Settings/Layout/PinCodeList";
import SubCategoryList from "./Settings/Layout/SubCategoryList";
import Settings from "./Settings/Settings";
import ViewUserNetwork from "./Settings/ViewUserNetwork";
import AdminViewOrder from "./ViewOrder/AdminViewOrder";
import AdminViewReturn from "./ViewReturn/AdminViewReturn";
// import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
// import Typography from '@mui/material/Typography';
import Modal from "@mui/material/Modal";
import { io } from "socket.io-client";
import server from "../server";
import Employees from "./Employee/Employee";
import AdminReturns from "./Returns/Returns";

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const adminOrderSocket = io(`${server}/admin/order`, {
  auth: { token: localStorage.getItem("accessToken") },
  closeOnBeforeunload: true,
  autoConnect: false,
  reconnectionDelay: 300,
});
adminOrderSocket.on("connect", () => {
  console.log("Connected to adminOrderSocket!");
});
adminOrderSocket.on("disconnect", () => {
  console.log("Disconnected to adminOrderSocket!");
});

const AdminNavbar = (props) => {
  const buzzerRef = useRef(null);

  useEffect(() => {
    adminOrderSocket.connect();

    adminOrderSocket.on("start-buzzer", () => {
      setLoopCount(8);
      setCurrentCount(0);
      buzzerRef.current.play();
      localStorage.setItem("start-buzzer", true);
    });
    adminOrderSocket.on("stop-buzzer", () => {
      console.log("stop-buzzer");
      buzzerRef.current.pause();
      localStorage.setItem("start-buzzer", false);
    });
  }, []);

  const [loopCount, setLoopCount] = useState(300);
  const [currentCount, setCurrentCount] = useState(0);

  useEffect(() => {
    console.log("navbar-mounted");
    const handleEnded = () => {
      console.log("buzzer ended");
      if (!localStorage.getItem("start-buzzer")) return;
      setCurrentCount((prevCount) => {
        if (prevCount < loopCount - 1) {
          buzzerRef.current.currentTime = 0; // Reset to the beginning
          buzzerRef.current.play(); // Play again
          return prevCount + 1; // Increment the count
        }
        return prevCount; // Do not increment after the final loop
      });
    };

    const audioElement = buzzerRef.current;

    if (audioElement) {
      audioElement.addEventListener("ended", handleEnded);
    }

    return () => {
      if (audioElement) {
        audioElement.removeEventListener("ended", handleEnded);
      }
      adminOrderSocket.disconnect();
    };
  }, []);

  const navigate = useNavigate();

  const context2 = useContext(DashboardContext);
  const { loading, goback, setGoBack } = context2;
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [newActiveLink, setNewActiveLink] = useState(null);

  const { pathname } = useLocation();
  const context = useContext(UserContext);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("adminuser");
    localStorage.removeItem("refresh");
    localStorage.clear();
    navigate("/admin/login");
    context.setUser(false);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const drawerWidth = 240;

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    padding: 4,
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#ffffff",
    overflowX: "scroll",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    border: "none",
    borderRadius: "12px",
    boxShadow: 24,
    backdropfilter: "blur(4px)",
  };

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
    background: "#ffffff",
    color: "#45464E",
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const isActive = (match, location) => {
    return ["/inventory", "/addproduct"].includes(location.pathname);
  };
  const [open1, setOpen1] = useState(false);
  const handleOpen = () => setOpen1(true);
  const handleClose = () => setOpen1(false);

  const [settingsopen, setSettingsOpen] = useState(false);
  const [subopen, setsubopen] = useState(false);
  const [pincodeopen, setpincodeopen] = useState(false);

  // useEffect(() => {}, [settingsopen, goback, pincodeopen]);
  if (localStorage.getItem("start-buzzer")) buzzerRef.current?.play();

  return (
    <Box>
      <audio
        ref={buzzerRef}
        src="/saturday-morning-149905.mp3"
        preload="auto"
      ></audio>
      <Backdrop sx={{ color: "#fff", zIndex: "1201" }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        open={open1}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you Sure, You want to log out?
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              className="create-new-post-button"
              style={{ margin: "10px 20px" }}
              onClick={handleLogout}
            >
              Logout
            </button>
            <button
              className="create-new-post-button"
              style={{ margin: "10px 20px" }}
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Box className="navbar">
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(open && { display: "none" }),
                }}
              >
                <img alt="logo" src={Graph} style={{ width: "40px" }} />
              </IconButton>
              {goback ? (
                <Button
                  sx={{
                    textDecoration: "none",
                    color: "black",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setGoBack(false);
                    navigate(-1);
                  }}
                >
                  <ArrowBackIosIcon />
                </Button>
              ) : (
                <></>
              )}
              <Typography variant="h6" noWrap component="Box">
                Dashboard
              </Typography>
            </Toolbar>
            {/* <Box className="navbar-profile-image">
							<img alt="notify" src={Notification} />
							<img
								alt="profile"
								src={Profile}
								onClick={() => props.setOpen(true)}
							/>
						</Box> */}
          </Box>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <Box className="navbar-logo">
              <img alt="logo" src={Graph} />
              <h4>Search-In</h4>
            </Box>

            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Box className="list-box">
            <Box className="list-child">
              <List>
                <ListItem disablePadding style={{ margin: "10px 10px" }}>
                  <NavLink
                    to="/dashboard"
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) =>
                      isActive ? "active-class" : "not-active-class"
                    }
                    activeclassname={({ isActive }) => {
                      setNewActiveLink(true);
                    }}
                  >
                    <Box style={{ width: "32vw" }}>
                      <ListItemButton
                        onClick={(e) => {
                          e.preventDefault();
                          setSettingsOpen(false);
                          setsubopen(false);
                          setpincodeopen(false);
                          setGoBack(false);
                          navigate("/dashboard");
                        }}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          {({ isActive }) => (
                            <img
                              alt="item"
                              src={isActive ? DashboardImg : DashNotActive}
                            />
                          )}
                          <img alt="item" src={DashNotActive} />
                          {/* {({ isActive }) => (
                            <>
                              {isActive ? (
                                <>
                                  <img alt="listItem" src={DashboardImg} />
                                </>
                              ) : (
                                <>
                                  <img alt="listItem" src={DashNotActive} />
                                </>
                              )}
                            </>
                          )} */}
                        </ListItemIcon>
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                          }}
                        >
                          Dashboard
                        </ListItemText>
                      </ListItemButton>
                    </Box>
                  </NavLink>
                </ListItem>

                <ListItem disablePadding style={{ margin: "10px 10px" }}>
                  <NavLink
                    to="/orders"
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) =>
                      isActive ? "active-class" : "not-active-class"
                    }
                  >
                    <Box style={{ width: "32vw" }}>
                      <ListItemButton
                        onClick={(e) => {
                          e.preventDefault();
                          setSettingsOpen(false);
                          setsubopen(false);
                          setGoBack(false);
                          setpincodeopen(false);

                          navigate("/orders");
                        }}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <img alt="listItem" src={Bag} />
                        </ListItemIcon>
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                          }}
                        >
                          Orders
                        </ListItemText>
                      </ListItemButton>
                    </Box>
                  </NavLink>
                </ListItem>

                <ListItem disablePadding style={{ margin: "10px 10px" }}>
                  <NavLink
                    to="/customers"
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) =>
                      isActive ? "active-class" : "not-active-class"
                    }
                  >
                    <Box style={{ width: "32vw" }}>
                      <ListItemButton
                        onClick={(e) => {
                          e.preventDefault();
                          setSettingsOpen(false);
                          setpincodeopen(false);

                          setsubopen(false);
                          setGoBack(false);
                          navigate("/customers");
                        }}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <img alt="listItem" src={User} />
                        </ListItemIcon>
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                          }}
                        >
                          Customers
                        </ListItemText>
                      </ListItemButton>
                    </Box>
                  </NavLink>
                </ListItem>

                <ListItem disablePadding style={{ margin: "10px 10px" }}>
                  <NavLink
                    to="/employees"
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) =>
                      isActive ? "active-class" : "not-active-class"
                    }
                  >
                    <Box style={{ width: "32vw" }}>
                      <ListItemButton
                        onClick={(e) => {
                          e.preventDefault();
                          setSettingsOpen(false);
                          setpincodeopen(false);

                          setsubopen(false);
                          setGoBack(false);
                          navigate("/employees");
                        }}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <img alt="listItem" src={User} />
                        </ListItemIcon>
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                          }}
                        >
                          Employees
                        </ListItemText>
                      </ListItemButton>
                    </Box>
                  </NavLink>
                </ListItem>

                <ListItem disablePadding style={{ margin: "10px 10px" }}>
                  <NavLink
                    to="/inventory"
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) =>
                      isActive ? "active-class" : "not-active-class"
                    }
                  >
                    <div style={{ width: "32vw" }}>
                      <ListItemButton
                        onClick={(e) => {
                          e.preventDefault();
                          setSettingsOpen(false);
                          setsubopen(false);
                          setpincodeopen(false);

                          setGoBack(false);
                          navigate("/inventory");
                        }}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <img alt="listItem" src={InventoryImg} />
                        </ListItemIcon>
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                          }}
                        >
                          Inventory
                        </ListItemText>
                      </ListItemButton>
                    </div>
                  </NavLink>
                </ListItem>

                <ListItem disablePadding style={{ margin: "10px 10px" }}>
                  <NavLink
                    to="/banners"
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) =>
                      isActive ? "active-class" : "not-active-class"
                    }
                  >
                    <div style={{ width: "32vw" }}>
                      <ListItemButton
                        onClick={(e) => {
                          e.preventDefault();
                          setSettingsOpen(false);
                          setpincodeopen(false);

                          setsubopen(false);
                          setGoBack(false);
                          navigate("/banners");
                        }}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <img alt="listItem" src={InventoryImg} />
                        </ListItemIcon>
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                          }}
                        >
                          Banners
                        </ListItemText>
                      </ListItemButton>
                    </div>
                  </NavLink>
                </ListItem>

                <ListItem disablePadding style={{ margin: "10px 10px" }}>
                  <NavLink
                    to="/returns"
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) =>
                      isActive ? "active-class" : "not-active-class"
                    }
                  >
                    <div style={{ width: "32vw" }}>
                      <ListItemButton
                        onClick={(e) => {
                          e.preventDefault();
                          setSettingsOpen(false);
                          setsubopen(false);
                          setpincodeopen(false);

                          setGoBack(false);
                          navigate("/returns");
                        }}
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <img alt="listItem" src={InventoryImg} />
                        </ListItemIcon>
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                          }}
                        >
                          Returns
                        </ListItemText>
                      </ListItemButton>
                    </div>
                  </NavLink>
                </ListItem>
                <ListItem disablePadding style={{ margin: "10px 10px" }}>
                  <NavLink
                    to="/pincodelist"
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) =>
                      isActive ? "active-class" : "not-active-class"
                    }
                  >
                    <div style={{ width: "32vw" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setSettingsOpen(true);
                          setsubopen(false);
                          setpincodeopen(true);
                          setGoBack(false);
                          navigate("/pincodelist");
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <img alt="listItem" src={Setting} />
                        </ListItemIcon>
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                          }}
                        >
                          PinCodes
                        </ListItemText>
                      </ListItemButton>
                    </div>
                  </NavLink>
                </ListItem>
                <ListItem disablePadding style={{ margin: "10px 10px" }}>
                  <NavLink
                    to="/categorylist"
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) =>
                      isActive ? "active-class" : "not-active-class"
                    }
                  >
                    <div style={{ width: "32vw" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setSettingsOpen(true);
                          setsubopen(true);
                          setGoBack(false);
                          navigate("/categorylist");
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <img alt="listItem" src={Setting} />
                        </ListItemIcon>
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                          }}
                        >
                          Categories
                        </ListItemText>
                      </ListItemButton>
                    </div>
                  </NavLink>
                </ListItem>
                <ListItem disablePadding style={{ margin: "10px 10px" }}>
                  <NavLink
                    to="/subcategorylist"
                    style={{ textDecoration: "none" }}
                    className={({ isActive }) =>
                      isActive ? "active-class" : "not-active-class"
                    }
                  >
                    <div style={{ width: "32vw" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: open ? "initial" : "center",
                          px: 2.5,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setSettingsOpen(true);
                          setsubopen(true);
                          setGoBack(false);
                          navigate("/subcategorylist");
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <img alt="listItem" src={Setting} />
                        </ListItemIcon>
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                          }}
                        >
                          Sub Categories
                        </ListItemText>
                      </ListItemButton>
                    </div>
                  </NavLink>
                </ListItem>
              </List>
            </Box>
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={handleOpen}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img alt="listItem" src={Logout} />
                  </ListItemIcon>
                  <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                    Logout
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3, background: "#f4f5fa" }}>
          <DrawerHeader />
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/employees" element={<Employees />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route
              path="/admin/vieworder/:orderId"
              element={<AdminViewOrder />}
            />
            <Route
              path="/admin/viewreturn/:returnId"
              element={<AdminViewReturn />}
            />
            <Route path="/addproduct" element={<NewProduct />} />
            <Route path="/editproduct" element={<NewProduct />} />
            <Route path="/banners" element={<Banners />} />
            <Route path="/categorylist" element={<CategoryList />} />
            <Route path="/subcategorylist" element={<SubCategoryList />} />
            <Route path="/viewproduct" element={<ViewProduct />} />
            <Route path="/pincodelist" element={<PinCodeList />} />
            <Route path="/viewcustomer" element={<CustomerOrder />} />
            <Route path="/content" element={<Content />} />
            <Route path="/returns" element={<AdminReturns />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/viewusernetwork" element={<ViewUserNetwork />} />
            <Route path="*" element={<Dashboard />} />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminNavbar;
