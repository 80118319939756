import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import server from "../../../server";

const CarouselBox = {
  overflow: "auto",
  padding: "20px",
};

const styles = {
  carouselBox: CarouselBox,
  gridItem: {
    cursor: "pointer",
    borderRadius: "10px",
    overflow: "hidden",
    transition: "transform 0.2s ease",
    "&:hover": {
      transform: "scale(1.02)",
    },
    "&:focus": {
      transform: "scale(1.02)",
    },
  },
  imageGrid: {
    borderRadius: "10px 10px 0 0",
    maxWidth: "45vw",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "6px", // Space between images
    minHeight: "45vw",
    padding: "8px",
  },
  image: {
    objectFit: "contain",
    maxWidth: "100%",
    borderRadius: "10px",
  },
  title: (backgroundColor) => ({
    width: "100%",
    maxWidth: "45vw",
    height: "fit-content",
    textAlign: "center",
    color: "#263e4f",
    backgroundColor: backgroundColor || "orange",
    padding: "8px 4px",
    paddingTop: 0,
    borderRadius: "0 0 10px 10px",
    fontFamily: "Quicksand, Poppins, Arial",
    fontWeight: 600,
    fontSize: 14,
  }),
};

const FeaturedProducts = ({}) => {
  const [recentOrders, setRecentOrders] = useState([]);
  const [wishlist, setWishlist] = useState([]);

  const navigate = useNavigate();

  const pagesMap = {
    recentOrders: {
      link: "/orders/recent/Previously Bought",
      title: "Previously Bought",
      endpoint: "/orders/recent",
      setter: setRecentOrders,
      getter: recentOrders,
      backgroundColor: "#e6f9d4",
    },
    wishlist: {
      link: "/wishlist",
      title: "Wishlist",
      endpoint: "/user/wishlist/populated",
      setter: setWishlist,
      getter: wishlist,
      backgroundColor: "#E1F1FF",
    },
  };

  const fetchProductsBySource = async (page) => {
    const { endpoint, setter } = pagesMap[page];

    try {
      const result = await axios.get(`${server}${endpoint}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        // params: { limit: 4 },
      });

      setter(result?.data.products.map((p) => p?.product || p));
    } catch (err) {
      console.log(err?.response?.data || err?.data || err);
    }
  };

  useEffect(() => {
    for (const page of Object.keys(pagesMap)) fetchProductsBySource(page);
  }, []);

  const countGrids = Object.keys(pagesMap)
    .map((page) => {
      const { getter } = pagesMap[page];
      return getter.length > 2;
    })
    .filter(Boolean).length;

  return (
    <Box sx={styles.carouselBox}>
      <Grid container spacing={2} wrap="nowrap" sx={{ borderRadius: 10 }}>
        {Object.keys(pagesMap).map((page) => {
          const { title, getter, backgroundColor, link } = pagesMap[page];

          return getter.length > 2 ? (
            <Grid
              // item={countGrids !== 1}
              item
              xs={12}
              sm={countGrids == 1 ? 12 : 6}
              md={countGrids == 1 ? 12 : 6}
              key={page}
              onClick={() => navigate(link)}
              sx={{
                ...styles.gridItem,
                // ...(countGrids == 1 ? { paddingLeft: 0 } : {}),
              }}
            >
              <Box
                sx={{
                  ...styles.imageGrid,
                  backgroundColor: backgroundColor || "orange",
                  ...(countGrids == 1
                    ? {
                        maxWidth: "100%",
                        gridTemplateColumns: "repeat(4, 1fr)",
                        minHeight: "fit-content",
                      }
                    : {}),
                }}
              >
                {getter
                  .filter(
                    (currproduct) =>
                      currproduct.published &&
                      currproduct.stocklimit &&
                      currproduct.stocklimit < currproduct.stock,
                  )
                  .slice(0, 4)
                  .map((currproduct) => (
                    <Box
                      key={currproduct._id}
                      component="img"
                      src={currproduct.imageUrl}
                      alt={`Product ${currproduct._id}`}
                      sx={styles.image}
                    />
                  ))}
              </Box>
              <Typography
                sx={{
                  ...styles.title(backgroundColor),
                  ...(countGrids == 1 ? { maxWidth: "100%" } : {}),
                }}
              >
                {title}
              </Typography>
            </Grid>
          ) : null;
        })}
      </Grid>
    </Box>
  );
};

export default FeaturedProducts;
